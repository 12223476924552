// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-episodes-js": () => import("./../src/pages/episodes.js" /* webpackChunkName: "component---src-pages-episodes-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-my-files-js": () => import("./../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-templates-episodes-js": () => import("./../src/templates/episodes.js" /* webpackChunkName: "component---src-templates-episodes-js" */),
  "component---src-templates-journal-js": () => import("./../src/templates/journal.js" /* webpackChunkName: "component---src-templates-journal-js" */)
}

